/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '../dialog';

const Layout = ({ children }) => {
  return (
    <>
      <header>Header</header>
      <main>{children}</main>
      <footer></footer>

      <Alert />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
